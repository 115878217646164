var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"contactPersonForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-card',[_c('b-col',_vm._l((_vm.contacts),function(contact,index){return _c('b-row',{key:(index + "-contact"),staticClass:"mt-2",attrs:{"id":index}},[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre completo*","label-for":(index + "ContactFullName")}},[_c('validation-provider',{attrs:{"name":"nombre completo","rules":"max:100","vid":(index + "ContactFullName")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":(index + "ContactFullName"),"state":errors.length > 0 ? false:null,"name":(index + "ContactFullName"),"placeholder":"Juan Alvaro Perez Gonzales"},model:{value:(contact.fullName),callback:function ($$v) {_vm.$set(contact, "fullName", $$v)},expression:"contact.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Cargo*","label-for":(index + "ContactPosition")}},[_c('validation-provider',{attrs:{"name":"cargo*","rules":"max:20","vid":(index + "ContactPosition")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":(index + "ContactPosition"),"state":errors.length > 0 ? false:null,"name":(index + "ContactPosition"),"placeholder":"Gerente"},model:{value:(contact.position),callback:function ($$v) {_vm.$set(contact, "position", $$v)},expression:"contact.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Número de teléfono*","label-for":(index + "ContactTelephone")}},[_c('validation-provider',{attrs:{"name":"número de Teléfono","rules":"min:9|max:15","vid":(index + "ContactTelephone")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":(index + "ContactTelephone"),"state":errors.length > 0 ? false:null,"name":(index + "ContactTelephone"),"formatter":_vm.formatterTel,"placeholder":"999 999 999"},model:{value:(contact.telephone),callback:function ($$v) {_vm.$set(contact, "telephone", $$v)},expression:"contact.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Correo electrónico*","label-for":(index + "ContactEmail")}},[_c('validation-provider',{attrs:{"name":"correo electrónico","rules":"email","vid":(index + "ContactEmail")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":(index + "ContactEmail"),"state":errors.length > 0 ? false:null,"name":(index + "ContactEmail"),"placeholder":"john@example.com"},model:{value:(contact.email),callback:function ($$v) {_vm.$set(contact, "email", $$v)},expression:"contact.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Número de DOI*","label-for":(index + "ContactDoi")}},[_c('validation-provider',{attrs:{"name":"número de DOI","rules":"document:0","vid":(index + "ContactDoi")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":(index + "ContactDoi"),"state":errors.length > 0 ? false:null,"name":(index + "ContactDoi")},model:{value:(contact.doi),callback:function ($$v) {_vm.$set(contact, "doi", $$v)},expression:"contact.doi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[(_vm.contacts.length < 5)?_c('b-col',{staticClass:"mb-50"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-button',{staticClass:"m-1 mt-md-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addNewConctact(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Añadir nuevo")])],1)],1)],1):_vm._e(),(_vm.contacts.length > 1)?_c('b-col',{staticClass:"mb-50"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-button',{staticClass:"m-1 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeContact(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"Trash2Icon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Eliminar")])],1)],1)],1):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)],1)],1)}),1)],1),_c('b-row',{staticClass:"justify-content-center my-2"},[_c('b-col',{attrs:{"sm":"6","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"secondary","to":{name:'Administration-aspects'},"block":""}},[_vm._v(" Volver ")])],1),_c('b-col',{attrs:{"sm":"6","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" Guardar y avanzar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }