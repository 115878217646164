<template>
  <!-- form -->
  <validation-observer ref="contactPersonForm">
    <b-form
      @submit.prevent="validationForm"
    >
      <b-card>
        <b-col>
          <b-row
            v-for="(contact, index) in contacts"
            :id="index"
            :key="`${index}-contact`"
            class="mt-2"
          >
            <!-- fullName -->
            <b-col
              sm="12"
            >
              <b-form-group
                label="Nombre completo*"
                :label-for="`${index}ContactFullName`"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nombre completo"
                  rules="max:100"
                  :vid="`${index}ContactFullName`"
                >
                  <b-form-input
                    :id="`${index}ContactFullName`"
                    v-model="contact.fullName"
                    :state="errors.length > 0 ? false:null"
                    :name="`${index}ContactFullName`"
                    placeholder="Juan Alvaro Perez Gonzales"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- position -->
            <b-col
              lg="6"
              xl="3"
            >
              <b-form-group
                label="Cargo*"
                :label-for="`${index}ContactPosition`"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cargo*"
                  rules="max:20"
                  :vid="`${index}ContactPosition`"
                >
                  <b-form-input
                    :id="`${index}ContactPosition`"
                    v-model="contact.position"
                    :state="errors.length > 0 ? false:null"
                    :name="`${index}ContactPosition`"
                    placeholder="Gerente"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- telephone -->
            <b-col
              lg="6"
              xl="3"
            >
              <b-form-group
                label="Número de teléfono*"
                :label-for="`${index}ContactTelephone`"
              >
                <validation-provider
                  #default="{ errors }"
                  name="número de Teléfono"
                  rules="min:9|max:15"
                  :vid="`${index}ContactTelephone`"
                >
                  <b-form-input
                    :id="`${index}ContactTelephone`"
                    v-model="contact.telephone"
                    :state="errors.length > 0 ? false:null"
                    :name="`${index}ContactTelephone`"
                    :formatter="formatterTel"
                    placeholder="999 999 999"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- email -->
            <b-col
              lg="6"
              xl="3"
            >
              <b-form-group
                label="Correo electrónico*"
                :label-for="`${index}ContactEmail`"
              >
                <validation-provider
                  #default="{ errors }"
                  name="correo electrónico"
                  rules="email"
                  :vid="`${index}ContactEmail`"
                >
                  <b-form-input
                    :id="`${index}ContactEmail`"
                    v-model="contact.email"
                    :state="errors.length > 0 ? false:null"
                    :name="`${index}ContactEmail`"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- borndate -->
            <b-col
              lg="6"
              xl="3"
            >
              <b-form-group
                label="Número de DOI*"
                :label-for="`${index}ContactDoi`"
              >
                <validation-provider
                  #default="{ errors }"
                  name="número de DOI"
                  rules="document:0"
                  :vid="`${index}ContactDoi`"
                >
                  <b-form-input
                    :id="`${index}ContactDoi`"
                    v-model="contact.doi"
                    :state="errors.length > 0 ? false:null"
                    :name="`${index}ContactDoi`"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- buttons -->
            <b-col
              lg="12"
            >
              <b-row
                class="justify-content-end"
              >
                <b-col md="6">
                  <b-row>
                    <b-col
                      v-if="contacts.length < 5"
                      class="mb-50"
                    >
                      <b-row class="justify-content-center">
                        <b-button
                          variant="primary"
                          class="m-1 mt-md-2"
                          @click="addNewConctact(index)"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span class="align-middle">Añadir nuevo</span>
                        </b-button>
                      </b-row>
                    </b-col>
                    <b-col
                      v-if="contacts.length > 1"
                      class="mb-50"
                    >
                      <b-row class="justify-content-center">
                        <b-button
                          variant="outline-danger"
                          class="m-1 mt-md-2"
                          @click="removeContact(index)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-25"
                          />
                          <span class="align-middle">Eliminar</span>
                        </b-button>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <!-- submit button -->
      <b-row
        class="justify-content-center my-2"
      >
        <b-col
          sm="6"
          md="3"
        >
          <!-- return button -->
          <b-button
            class="my-1"
            variant="secondary"
            :to="{name:'Administration-aspects'}"
            block
          >
            Volver
          </b-button>
        </b-col>
        <b-col
          sm="6"
          md="3"
        >
          <b-button
            class="my-1"
            variant="primary"
            block
            type="submit"
          >
            Guardar y avanzar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BCard, BFormGroup, BFormInput, BButton, BRow, BCol,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    // Max date for year validation
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setFullYear(maxDate.getFullYear() - 18)
    return {
      // Initial values for the data
      contacts: [
        {
          fullName: JSON.parse(localStorage.getItem('userData')).names,
          position: JSON.parse(localStorage.getItem('userData')).role,
          telephone: '',
          email: '',
          doi: '',
        },
      ],
      maxDate,
      // Validation rules
      required,
    }
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))

    if (dataU.contactPerson && dataU.contactPerson.contacts) {
      this.contacts = JSON.parse(JSON.stringify(dataU.contactPerson.contacts))
    } else {
      this.contacts[0].fullName = dataU.contactPerson && dataU.contactPerson.fullName ? dataU.contactPerson.fullName : ''
      this.contacts[0].position = dataU.contactPerson && dataU.contactPerson.position ? dataU.contactPerson.position : ''
      this.contacts[0].telephone = dataU.contactPerson && dataU.contactPerson.telephone ? dataU.contactPerson.telephone : ''
      this.contacts[0].email = dataU.contactPerson && dataU.contactPerson.email ? dataU.contactPerson.email : ''
      this.contacts[0].doi = dataU.contactPerson && dataU.contactPerson.doi ? dataU.contactPerson.doi : ''
    }
  },
  methods: {
    formatterTel(value) {
      return value.replace(/[^0-9()+]/g, '')
    },
    validationForm() {
      this.$refs.contactPersonForm.validate().then(success => {
        if (success) {
          useJwt.contactPerson({
            email: JSON.parse(localStorage.getItem('userData')).email,
            ruc: JSON.parse(localStorage.getItem('userData')).ruc,
            contactPerson: {
              contacts: this.contacts,
            },
            /* contactPerson: {
              fullName: this.fullName,
              position: this.position,
              telephone: this.telephone,
              email: this.email,
              doi: this.doi,
            }, */
          })
            .then(({ data }) => {
              const user = data.user.value
              localStorage.setItem('userData', JSON.stringify(user))
              store.dispatch(
                'elementVerticalMenu/setStatusStep',
                { step: 'Contact-person', status: user.contactPerson.completed },
                { root: true },
              )
              this.$router.replace('/registro/cuentas-bancarias-vigentes')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Se ha guardado tu información con éxito.',
                      autoHideDelay: 5000,
                    },
                  })
                })
            })
            .catch(error => {
              console.log('errores', error)
              this.$refs.contactPersonForm.setErrors(error.response.data.error)
            })
        }
      })
    },
    addNewConctact(index) {
      this.contacts.splice(index + 1, 0,
        {
          fullName: '',
          position: '',
          telephone: '',
          email: '',
          doi: '',
        })
    },
    removeContact(index) {
      this.contacts.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
